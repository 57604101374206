import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const BotoxPage = () => (
    <Layout>
    <SEO title="Botox - The Cosmetic Treatment | The Smile Stylist"
     description="Everyone needs a healthy lifestyle with least signs of pain. Botox cosmetic treatment is a nonsurgical process to reduce your pain. Book your free consultation now!"
      keywords="Botox , porcelain veneers, dental clinics, oakville, toronto, mississauga" />
    <main className="site-content" role="main">
    <section className="botox__banner">
      <img src={require("../images/desktop/parallax/botox.png") } alt="Botox" />
    </section>
    <section className="botox__text">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="botox__title">
              therapeutic botox
            </h1>
            <div className="botox__desc">
              Chronic headache, jaw pain and/or neck pain should not be a part of your every day lfe. At the Smilestylist, we are committed to ensuring we find every possible solution to treat your symptoms and ensure you have a helathy, pain free lifestyle. Our Therapeutic Botox© treatments with may be the solution that you’ve been looking for.
            </div>
          </div>
        </div>
        <div className="row botox__features">
          <div className="col-md-4">
            <div className="botox__img__outer">
              <img src={require("../images/desktop/botox-pm.png") } alt="Pain Management" className="botox__feature__image" />
            </div>
            <h3 className="botox__feature__title">
              Pain Management
            </h3>
            <p className="botox__feature__text">
              Therapeutic Botox© is used primarily to relieve our patients of a number of painful chronic conditions such as TMD (Temporomandibular Joint Disorder). Botox© is injected below the skin in targeted areas to relax certain muscles which helps prevent continuing pain or discomfort. Our Botox services have the ability to treat a number of conditions, from jaw pain, neck pain as well as spasms.
            </p>
          </div>
          <div className="col-md-4">
            <div className="botox__img__outer">
              <img src={require("../images/desktop/botox-th.png") } alt="Tension Headaches" className="botox__feature__image" />
            </div>
            <h3 className="botox__feature__title">Tension Headaches</h3>
            <p className="botox__feature__text">Many people suffer from crippling and chronic tension headaches that interfere with their ability to live normal and healthy lives. For many it can be a life long process trying to find the ways to minimize the effects of these devastating headaches. Our Botox therapy may be the best solution for you. Botox helps relax the facial muscles that tense up that are often responsible for causing these debilitating headaches. </p>
          </div>
          <div className="col-md-4">
            <div className="botox__img__outer">
              <img src={require("../images/desktop/botox-tg.png") } alt="Teeth Grinding" className="botox__feature__image" />
            </div>
            <h3 className="botox__feature__title">Teeth Grinding</h3>
            <p className="botox__feature__text">Chronic Bruxism or Chronic Teeth Grinding is a condition that about 8 per cent of Canadian adults and 13 per cent of Canadian children suffer from. The damage caused is often irreversible and could have devastating effects on your oral health. While Mouth guards have proven to be beneficial there are still many consequences felt by the constant jaw movements. Botox is often the best solution for your long term oral health. </p>
          </div>
        </div>
        <div className="row">
          <a className="botox__book" href="/">Book FREE Consult</a>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default BotoxPage